import {
    ReactNode,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    ClientLayerItem,
    HydrantLayerItem,
    JobLayerItem,
    StationLayerItem,
    SubscriptionLayers,
    useLayers,
} from "../../utils/mapManager";
import { JobTypeInformation } from "../../models/jobTypeInformation";
import { AgencyLayerItem } from "../../utils/mapManager";
import { Polygon, MultiPolygon, Point } from "geojson";
import { LayerType } from "../../utils/mapManager";
import { LatLng, geoJSON } from "leaflet";
import { LayerItemAny } from "../../utils/mapManager";
import { BryxColors } from "../../utils/bryxColors";
import { DateUtils } from "../../utils/dateUtils";
import { PreferenceManager } from "../../utils/preferenceManager";
import React from "react";
import { LayerGroup, Popup } from "react-leaflet";
import { BryxDivMarker, BryxPolyMarker } from "@bryxinc/ui";

export type CachedPin = (
    | {
          type: "jobs";
          item: JobLayerItem;
          jobType: JobTypeInformation;
      }
    | {
          type: "apparatus" | "users";
          item: ClientLayerItem;
      }
    | {
          type: "stations";
          item: StationLayerItem;
      }
    | {
          type: "agencies";
          item: AgencyLayerItem;
          polygons: Polygon[];
      }
    | {
          type: "hydrants";
          item: HydrantLayerItem;
      }
) & { id: string };

const jobIconMap = {
    fire: "job_map_pins/fire_pin.svg",
    ems: "job_map_pins/ems_pin.svg",
    police: "job_map_pins/police_pin.svg",
    info: "job_map_pins/info_pin.svg",
    water: "job_map_pins/water_pin.svg",
    test: "job_map_pins/fire_pin.svg",
};

function Pin(props: {
    icon: string;
    location: Point;
    extText?: string;
    children?: ReactNode;
}) {
    return (
        <BryxDivMarker
            icon={
                <div
                    style={{
                        position: "relative",
                    }}
                >
                    <img
                        src={`/resources/assets/${props.icon}`}
                        style={{
                            width: "24px",
                            height: "45px",
                            display: "inline-block",
                        }}
                    />
                    {props.extText && (
                        <span
                            className="ext-text"
                            style={{
                                position: "absolute",
                                top: "18px",
                                left: "50%",
                                transform: "translate(-50%, 0)",
                            }}
                        >
                            {props.extText}
                        </span>
                    )}
                </div>
            }
            position={
                new LatLng(
                    props.location.coordinates[1],
                    props.location.coordinates[0]
                )
            }
            iconAnchor={[10, 45]}
            iconSize={[24, 45]}
        >
            {props.children && <Popup autoPan={false} keepInView={false} offset={[0, -40]}>
                {props.children}
            </Popup>}
        </BryxDivMarker>
    );
}

const Annotation = memo(
    ({ pin, showHydrants }: { pin: CachedPin; showHydrants: boolean }) => {
        if (pin.type == "jobs") {
            const job = pin.item;
            return (
                <Pin
                    icon={jobIconMap[pin.jobType.type]}
                    location={pin.item.location as Point}
                    key={pin.id}
                >
                    <>
                        <div
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            {job.unitShortNames
                                .concat([job.synopsis])
                                .join(" - ")}
                        </div>
                        <div
                            style={{
                                color: BryxColors.gray,
                            }}
                        >
                            {job.locationOfIncident?.join
                                ? job.locationOfIncident.join(" - ")
                                : job.locationOfIncident}
                        </div>
                        <div
                            style={{
                                color: BryxColors.lightGray,
                            }}
                        >
                            {DateUtils.formatDateTime(
                                new Date(job.ts),
                                PreferenceManager.shared.preferences
                            )}
                        </div>
                    </>
                </Pin>
            );
        }

        if (pin.type == "apparatus") {
            return (
                <Pin
                    icon={"client_map_pins/truck_pin.png"}
                    location={pin.item.location as Point}
                    extText={pin.item.initials}
                    key={pin.id}
                >
                    <>
                        <span>{pin.item.name}</span>
                    </>
                </Pin>
            );
        }

        if (pin.type == "stations") {
            return (
                <Pin
                    icon={"station_pin.svg"}
                    location={pin.item.location as Point}
                    key={pin.id}
                >
                    <>
                        <span>{pin.item.name}</span>
                    </>
                </Pin>
            );
        }

        if (pin.type == "users") {
            return (
                <Pin
                    icon={"client_map_pins/user_pin.png"}
                    location={pin.item.location as Point}
                    extText={pin.item.initials}
                    key={pin.id}
                >
                    <>
                        <span>{pin.item.name}</span>
                    </>
                </Pin>
            );
        }

        if (pin.type == "hydrants" && showHydrants) {
            return (
                <BryxDivMarker
                    icon={
                        <div
                            style={{
                                position: "relative",
                            }}
                        >
                            <img
                                src={"/resources/assets/hydrant_pin.svg"}
                                style={{
                                    width: "24px",
                                    height: "45px",
                                    display: "inline-block",
                                }}
                            />
                            <span
                                style={{
                                    position: "absolute",
                                    top: "22px",
                                    left: "50%",
                                    transform: "translate(-50%, 0px)",
                                    color: "white",
                                }}
                            >
                                {pin.item.mainSize}
                            </span>
                        </div>
                    }
                    position={
                        new LatLng(
                            (pin.item.location as Point).coordinates[1],
                            (pin.item.location as Point).coordinates[0]
                        )
                    }
                    iconAnchor={[10, 45]}
                    iconSize={[24, 45]}
                    key={pin.id}
                />
            );
        }

        if (pin.type == "agencies") {
            return (
                <>
                    {pin.polygons.map((polygon, key) => (
                        <BryxPolyMarker polygon={polygon} key={key} />
                    ))}
                </>
            );
        }
        throw new Error("Unreachable");
    },
    (prevProps, nextProps) =>
        prevProps.pin.id == nextProps.pin.id &&
        prevProps.showHydrants == nextProps.showHydrants
);

export function Pins(props: {
    geometry: Polygon;
    zoom: number;
    visibleLayers: LayerType[];
}) {
    const bounds = useMemo(() => {
        const feature = geoJSON(props.geometry);
        return feature.getBounds();
    }, [props.geometry]);
    const layers = useLayers(
        {
            latitude: Math.round(bounds.getNorthEast().lat * 10000) / 10000,
            longitude: Math.round(bounds.getNorthEast().lng * 10000) / 10000,
        },
        {
            latitude: Math.round(bounds.getSouthWest().lat * 10000) / 10000,
            longitude: Math.round(bounds.getSouthWest().lng * 10000) / 10000,
        }
    );
    const [cachedAgencies, setCachedAgencies] = useState<{
        [id: string]: CachedPin;
    }>({});

    const pinData = useMemo(
        () =>
            Object.entries(layers)
                .flatMap(([layer, layerItem]) =>
                    layerItem?.map((item) => {
                        if (layer == "jobs") {
                            return {
                                id: item.id,
                                type: "jobs",
                                item: item,
                                jobType: item.type,
                            };
                        }

                        if (
                            layer == "apparatus" ||
                            layer == "users" ||
                            layer == "stations"
                        ) {
                            return {
                                id: item.id,
                                type: layer,
                                item: item,
                            };
                        }

                        if (layer == "agencies") {
                            return {
                                id: item.id,
                                type: "agencies",
                                item: item,
                                polygons:
                                    item.location.type == "Polygon"
                                        ? [item.location]
                                        : (
                                              item.location as MultiPolygon
                                          ).coordinates.map((poly) => {
                                              return {
                                                  type: "Polygon",
                                                  coordinates: poly,
                                              };
                                          }),
                            };
                        }

                        if (layer == "hydrants") {
                            return {
                                id: item.id,
                                type: "hydrants",
                                item: item,
                            };
                        }
                    })
                )
                .filter(Boolean) as CachedPin[],
        [layers]
    );
    
    // Cache agencies to prevent agency polygons from disappearing on close zoom levels
    useEffect(
        () =>
            setCachedAgencies(
                Object.values(cachedAgencies)
                    .concat(pinData.filter((pin) => pin.type == "agencies"))
                    .reduceRight(
                        (previous, current) =>
                            Object.keys(previous).includes(current.id)
                                ? previous
                                : { ...previous, [current.id]: current },
                        {}
                    )
            ),
        [pinData]
    );

    const concatMarkerData = useMemo(
        () =>
            Object.values(
                pinData
                    .concat(Object.values(cachedAgencies))
                    .reduceRight<{ [id: string]: CachedPin }>(
                        (previous, current) =>
                            Object.keys(previous).includes(current.id)
                                ? previous
                                : { ...previous, [current.id]: current },
                        {}
                    )
            ),
        [pinData, cachedAgencies]
    );

    const visiblePins = useMemo(
        () =>
            concatMarkerData.filter(
                (pin) =>
                    props.visibleLayers.includes(pin.type) ||
                    props.visibleLayers.includes(`agency:${pin.id}`)
            ),
        [props.visibleLayers, concatMarkerData]
    );

    return (
        <LayerGroup>
            {visiblePins.map((pin, index) => (
                <Annotation
                    pin={pin}
                    showHydrants={props.zoom >= 18}
                    key={pin.id}
                />
            ))}
        </LayerGroup>
    );
}
