import {Options} from "i18next";
import * as i18n from "i18next";
import * as XHR from 'i18next-xhr-backend';
import * as React from "react";
import {FormEvent, SyntheticEvent} from "react";
import {Redirect} from "react-router-dom";
import {Button, Divider, Form, Grid, Input, Message, Popup} from "semantic-ui-react";
import {ContactSupportModal} from "./components/modals/contactSupportModal";
import {ForgotPasswordModal} from "./components/modals/forgotPasswordModal";
import {config} from "./config";
import {Auth} from "./models/auth";
import {ApiResult, BryxApi} from "./utils/bryxApi";
import {BryxLocal} from "./utils/bryxLocal";
import { ContactSupportModalState, ForgotPasswordModalState, ModalContext, ModalContextType } from "./components/modals/modalManager";

interface LoginState {
    status: LoginStatus;
    overlayContent: "none" | "contactSupport" | "forgotPassword";
    email: string | null;
    password: string | null;
    initializing: boolean;
}

type LoginStatus =
    { key: "ready", messageContent: "none" | "passwordChanged" | "forgotPasswordSent" | "forceSignedOut" } |
    { key: "loading" } |
    { key: "success", redirectLocation: string } |
    { key: "error", alertMessage: string };

const Branding = () => (
    <div className="logoAndText">
        <img src={"/resources/assets/logo_white.png"} style={{width: "30px"}}/> <span
        className="siteName">{i18n.t("branding.siteName")}</span>
    </div>
);

export class Login extends React.Component<any, LoginState> {
    static contextType: React.Context<ModalContextType | null> | undefined = ModalContext;
    context: ModalContextType;
    constructor(props: any, context: any) {
        super(props, context);

        let status: LoginStatus;
        if (BryxLocal.isSignedIn()) {
            status = {key: "success", redirectLocation: this.getRedirectLocation()};
        } else {
            i18n
                .use(XHR)
                .init({
                    lng: BryxLocal.getItem<string>("locale") || "en-US",
                    backend: {
                        loadPath: "/resources/locales/{{lng}}.json",
                    },
                    fallbackLng: "en",
                } as Options, () => {
                    this.setState({
                        initializing: false,
                    });
                });

            if (this.props.location.state) {
                switch (this.props.location.state.type) {
                    case "forced":
                        status = {key: "ready", messageContent: "forceSignedOut"};
                        break;
                    case "passwordChanged":
                        status = {key: "ready", messageContent: "passwordChanged"};
                        break;
                    default:
                        status = {key: "ready", messageContent: "none"};
                }
            } else {
                status = {key: "ready", messageContent: "none"};
            }
        }

        this.state = {
            email: "",
            password: "",
            overlayContent: "none",
            initializing: true,
            status: status,
        };
    }

    private getRedirectLocation(): string {
        if (this.props.location.state && this.props.location.state.from) {
            return this.props.location.state.from;
        } else {
            return "/jobs";
        }
    }

    private onSubmit(e: FormEvent<any>) {
        e.preventDefault();
        this.submitCredentials();
    }

    private submitCredentials(): void {
        this.setState({
            status: {key: "loading"},
        });

        const {email, password} = this.state;

        if (email == null || email == "" || password == null || password == "") {
            this.setState({
                status: {
                    key: "error",
                    alertMessage: i18n.t("login.blankEmailOrPassword"),
                },
            });
            return;
        }

        BryxApi.signIn(email, password, null, (result: ApiResult<Auth>) => {
            if (result.success == true) {
                const redirectLocation = this.getRedirectLocation();
                this.setState({
                    status: {
                        key: "success",
                        redirectLocation: redirectLocation,
                    },
                });
                config.info(`User successfully signed in, redirecting to ${redirectLocation}`);
            } else {
                this.setState({
                    status: {
                        key: "error",
                        alertMessage: result.message,
                    },
                });
                config.warn(`User failed to sign in: ${result.debugMessage}`);
            }
        });
    }

    private onChangeEmail(event: SyntheticEvent<HTMLInputElement>) {
        this.setState({
            email: event.currentTarget.value,
        });
    }

    private onChangePassword(event: SyntheticEvent<HTMLInputElement>) {
        this.setState({
            password: event.currentTarget.value,
        });
    }

    render() {
        if (this.state.status.key == "success") {
            return <Redirect to={this.state.status.redirectLocation}/>;
        }

        if (this.state.initializing) {
            return null;
        }

        let alertString = null;
        let alertType: null | "negative" | "warning" = null;

        if (this.state.status.key == "error") {
            alertString = this.state.status.alertMessage;
            alertType = "negative";
        } else if (this.state.status.key == "ready") {
            switch (this.state.status.messageContent) {
                case "forceSignedOut":
                    alertString = i18n.t("login.forceSignedOut");
                    alertType = "negative";
                    break;
                case "passwordChanged":
                    alertString = i18n.t("login.passwordChanged");
                    alertType = "warning";
                    break;
            }
        }

        const alertMessage = alertString && alertType ? (
            <Message negative={alertType == "negative"}
                     warning={alertType == "warning"}
                     content={alertString}/>
        ) : null;

        const emailInputHtml = (
            <Input required
                   autoFocus
                   autoComplete={false}
                   autoCorrect={false}
                   autoCapitalize={false}
                   spellCheck={false}
                   type="username"
                   placeholder={i18n.t("login.email")}
                   value={this.state.email || ""}
                   disabled={this.state.status.key == "loading"}
                   onChange={this.onChangeEmail.bind(this)}/>
        );

        const pwInputHtml = (
            <Input required
                   type="password"
                   placeholder={i18n.t("login.password")}
                   value={this.state.password || ""}
                   disabled={this.state.status.key == "loading"}
                   onChange={this.onChangePassword.bind(this)}/>
        );

        const inputForm = (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <Form.Field>{emailInputHtml}</Form.Field>
                <Form.Field>{pwInputHtml}</Form.Field>
                <Form.Field>
                    <Grid>
                        <Grid.Row columns={2} centered>
                            <Grid.Column style={{ paddingRight: "0.5rem" }}>
                                <Popup
                                    position="left center"
                                    on="click"
                                    style={{ textAlign: "center" }}
                                    content={i18n.t(
                                        "login.forgotPasswordSuccess"
                                    )}
                                    open={
                                        this.state.status.key == "ready" &&
                                        this.state.status.messageContent ==
                                            "forgotPasswordSent"
                                    }
                                    onClose={() =>
                                        this.setState({
                                            status: {
                                                key: "ready",
                                                messageContent: "none",
                                            },
                                        })
                                    }
                                    trigger={
                                        <Button
                                            className="btn"
                                            inverted
                                            color="grey"
                                            type="button"
                                            style={{ letterSpacing: "1px" }}
                                            onClick={() =>
                                                this.context.open<ForgotPasswordModalState>(
                                                    "forgotPassword",
                                                    {
                                                        email:
                                                            this.state.email ??
                                                            "",
                                                        onEmailChange:
                                                            (newEmail: string) => this.setState({
                                                                email: newEmail,
                                                            }),
                                                        onSuccess: () => {
                                                            this.context.close();
                                                            this.setState({
                                                                status: {
                                                                    key: "ready",
                                                                    messageContent:
                                                                        "forgotPasswordSent",
                                                                },
                                                            });
                                                        }
                                                    }
                                                )
                                            }
                                        >
                                            {i18n
                                                .t("login.forgotPassword")
                                                .toUpperCase()}
                                        </Button>
                                    }
                                />
                            </Grid.Column>
                            <Grid.Column style={{ paddingLeft: "0.5rem" }}>
                                <Button
                                    id="signinButton"
                                    className="btn"
                                    type="submit"
                                    loading={this.state.status.key == "loading"}
                                    style={{
                                        color: "#C61A1B",
                                        letterSpacing: "1px",
                                    }}
                                >
                                    {i18n.t("login.signIn").toUpperCase()}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form.Field>
            </Form>
        );

        return (
            <div className="login-page">
                <Branding/>
                <div id="contactSupportDiv">
                    <a
                        id="contactSupportLink"
                        onClick={() => this.context.open<ContactSupportModalState>("contactSupport", {email: this.state.email ?? ""})}
                    >
                        {i18n.t("contactSupport.header")}
                    </a>
                </div>
                <div className="login-modal">
                    <Grid className="login-modal-grid" verticalAlign="middle">
                        <Grid.Row centered>
                            <Grid.Column textAlign="left" id="loginForm">
                                <img id="signinLogo" src="/resources/assets/brand_logo.png"/>
                                {inputForm}
                                {alertMessage}
                                <Divider className="grayDivider"/>
                                <a
                                    id="joinLink"
                                    target="_blank"
                                    style={{textDecoration: "none"}}
                                    href={BryxApi.joinUrl}>
                                    {i18n.t("login.joinLink")}
                                </a>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        );
    }
}
