import * as i18n from "i18next";
import * as React from "react";
import { LayersControl, Marker, Popup, TileLayer } from "react-leaflet";
import { RouteComponentProps } from "react-router";
import { BryxMapUtil } from "../../components/bryxMap";
import { BryxMap } from "@bryxinc/ui";
import { DateUtils } from "../../utils/dateUtils";
import { JobListType } from "../../utils/jobManager";
import {
    LocationManager,
    LocationManagerObserver,
} from "../../utils/locationManager";
import {
    BryxPreferences,
    PreferenceManager,
    PreferenceManagerObserver,
} from "../../utils/preferenceManager";
import { LayerType } from "../../utils/mapManager";
import { BryxLocal } from "../../utils/bryxLocal";
import { LayerToggle } from "./layerToggle";
import { Pins } from "./mapPin";
import { Polygon, Point } from "geojson";
import { BryxApi } from "../../utils/bryxApi";
import {
    polygon,
    latLng,
    Icon,
    LatLngTuple,
    latLngBounds,
    rectangle,
    LatLng,
} from "leaflet";

interface MapPageState {
    userPosition: GeolocationPosition | null;
    preferences: BryxPreferences;
    currentTime: Date;
    geometry: Polygon;
    visibleLayers: LayerType[];
    zoom: number;
}

type MapLayerType = { i18nKey: string; id: string };

export class MapPage
    extends React.Component<RouteComponentProps<{}>, MapPageState>
    implements PreferenceManagerObserver, LocationManagerObserver
{
    private static readonly BOUNDS_MAX_DISTANCE_RADIUS = 100 * 1000; // 100 km
    private static readonly BOUNDS_PAD = 0.5;

    private mapLayerTypes: MapLayerType[];

    private selectedLayerId: any;

    private mapLayers: any;

    constructor(props: RouteComponentProps<{}>, context: any) {
        super(props, context);

        this.mapLayerTypes = [
            {
                i18nKey: "streets",
                id: "streets-v11",
            },
            {
                i18nKey: "satellite",
                id: "satellite-v9",
            },
            {
                i18nKey: "hybrid",
                id: "satellite-streets-v11",
            },
            {
                i18nKey: "dark",
                id: "dark-v10",
            },
        ];

        this.selectedLayerId =
            BryxLocal.getCurrentBaseLayerId() ||
            this.mapLayerTypes[
                localStorage.getItem("darkMode") == "true"
                    ? this.mapLayerTypes.length - 1
                    : 0
            ].id;

        this.mapLayers = this.mapLayerTypes.map((type) => (
            <LayersControl.BaseLayer
                key={`mapLayer:${type.id}`}
                checked={type.id == this.selectedLayerId}
                name={i18n.t("map." + type.i18nKey) ?? type.i18nKey}
            >
                <TileLayer
                    url={BryxMapUtil.MB_STYLE_URL}
                    accessToken={BryxMapUtil.ACCESS_TOKEN}
                    tileSize={512}
                    zoomOffset={-1}
                    id={type.id}
                    attribution={BryxMapUtil.MB_ATTRIBUTION}
                />
            </LayersControl.BaseLayer>
        ));

        this.state = {
            userPosition: LocationManager.shared.currentPosition,
            preferences: PreferenceManager.shared.preferences,
            currentTime: DateUtils.bryxNow(),
            geometry: rectangle(
                latLngBounds(
                    [24.7433195, -124.7844079],
                    [49.3457868, -66.9513812]
                )
            ).toGeoJSON().geometry as Polygon,
            visibleLayers: [
                "agencies",
                "apparatus",
                "hydrants",
                "jobs",
                "self",
                "stations",
                "users",
            ],
            zoom: 1,
        };
    }

    componentDidMount() {
        PreferenceManager.shared.registerObserver(this);
        LocationManager.shared.registerObserver(this);

        const user = this.userPosition();

        BryxApi.loadJobs(new Date(0), 1000, JobListType.open, (result) => {
            if (result.success && result.value.length > 0) {
                const points = [
                    ...result.value
                        .filter((job) => job.centroid)
                        .filter(
                            (job) =>
                                !user ||
                                (job.centroid &&
                                    user.distanceTo(
                                        latLng([
                                            job.centroid.coordinates[1],
                                            job.centroid.coordinates[0],
                                        ])
                                    ) < MapPage.BOUNDS_MAX_DISTANCE_RADIUS)
                        )
                        .map((job) => {
                            const center = job.centroid as Point;
                            return [
                                center.coordinates[1],
                                center.coordinates[0],
                            ];
                        }),
                ] as LatLngTuple[];
                if (user) {
                    points.push([user.lat, user.lng]);
                }
                this.setState({
                    geometry: rectangle(
                        polygon(points).getBounds().pad(MapPage.BOUNDS_PAD)
                    ).toGeoJSON().geometry as Polygon,
                });
            }
        });
    }

    componentWillUnmount() {
        PreferenceManager.shared.unregisterObserver(this);
        LocationManager.shared.unregisterObserver(this);
    }

    // PreferenceManagerObserver Functions

    preferencesManagerDidUpdatePreferences(newPrefs: BryxPreferences): void {
        this.setState({ preferences: newPrefs });
    }

    // LocationManagerObserver Functions

    locationManagerDidUpdatePosition(position: GeolocationPosition): void {
        this.setState({ userPosition: position });
    }

    locationManagerDidClearPosition(): void {
        this.setState({ userPosition: null });
    }

    userPosition(): LatLng | null {
        const { userPosition } = this.state;
        return userPosition != null
            ? latLng(
                  userPosition.coords.latitude,
                  userPosition.coords.longitude
              )
            : null;
    }

    render() {
        const { userPosition } = this.state;
        const userLatLng = this.userPosition();
        return (
            <div id="map-page">
                <LayerToggle
                    layers={this.state.visibleLayers}
                    onChange={(layers) =>
                        this.setState({ visibleLayers: layers })
                    }
                />
                <BryxMap
                    defaultZoom={5}
                    onZoomChange={(zoom) => this.setState({ zoom })}
                    onCenterChange={(geometry) => this.setState({ geometry })}
                    center={this.state.geometry}
                >
                    <LayersControl position="topright">
                        {this.mapLayers}
                    </LayersControl>

                    <Pins
                        geometry={this.state.geometry}
                        zoom={this.state.zoom}
                        visibleLayers={this.state.visibleLayers}
                    />
                    {userLatLng != null &&
                    userPosition != null &&
                    this.state.visibleLayers.includes("self") ? (
                        <Marker
                            icon={
                                new Icon({
                                    iconUrl:
                                        "/resources/assets/my_location.gif",
                                })
                            }
                            position={userLatLng}
                        >
                            <Popup
                                autoPan={false}
                                keepInView={false}
                                offset={[0, -10]}
                            >
                                <div>
                                    <span
                                        style={{
                                            display: "block",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {i18n.t("map.myLocation")}
                                    </span>
                                    <span style={{ display: "block" }}>
                                        {i18n.t("map.lastUpdatedX", {
                                            time: DateUtils.duration(
                                                new Date(
                                                    userPosition.timestamp
                                                ),
                                                this.state.currentTime
                                            ).humanize(true),
                                        })}
                                    </span>
                                </div>
                            </Popup>
                        </Marker>
                    ) : null}
                </BryxMap>
            </div>
        );
    }
}
